import React, { Component } from 'react'
/* import { Button } from '@material-ui/core'
import App from '../../App' */

interface Props {}
interface State {
  width: number
  height: number
}

class HomeView extends Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = { width: 0, height: 0 }
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render () {
    /* const { router } = App
    const { width, height } = this.state */

    return (
      <div>
        {/* <Button
          onClick={() => {
            router.goToForgotPassword({
              facilityId: '8371e23d-a5bf-42c3-b143-df887641f694'
            })
          }}
        >
          TEMP Forgot Password
        </Button>
        <Button
          onClick={() => {
            router.goToCreateAccount({
              facilityId: '8371e23d-a5bf-42c3-b143-df887641f694',
              userId: 'aa42bb00-cf98-4d2a-99bd-da2f15055bfd',
              email: 'lisahamber@mwcloud.com',
              checksum: 'c'
            })
          }}
        >
          TEMP Create Account
        </Button>
        <Button
          onClick={() => {
            router.goToChangePassword({
              facilityId: '8371e23d-a5bf-42c3-b143-df887641f694',
              userId: 'aa42bb00-cf98-4d2a-99bd-da2f15055bfd',
              email: 'lisahamber@mwcloud.com',
              oneTimeToken: 't'
            })
          }}
        >
          TEMP Change Password
        </Button> */}
      </div>
    )
  }
}

export default HomeView
