import React, { Component } from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  ReactRouter as Router,
  ReactRouterSwitch as Switch,
  ReactRouterPublicRoute as PublicRoute,
  ReactRouterRedirectRoute as RedirectRoute,
  DefaultRoutes,
  reactI18NextWithTranslation as withTranslation
} from 'mw-react-web-app-infrastructure'
import App from '../../App'
import Routes from '../../infrastructure/routing/Routes'
import HomeView from '../home/HomeView'
import Notifier from '../../components/notifier/Notifier'
import MainViewStyles from './MainView.styles'
import ForgotPassword from '../forgot-password/ForgotPassword'
import CreateAccount from '../create-account/CreateAccount'
import Images from '../../assets/images'
import Policies from '../policies/Policies'
import ChangePassword from '../change-password/ChangePassword'

interface MainViewProps {}
interface MainViewState {
  userIsAuthenticatedInFacility: boolean
}

class MainView extends Component<MainViewProps & WithStyles<typeof MainViewStyles>, MainViewState> {
  render () {
    const { router } = App
    const history = router.history
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Notifier />

        <div className={classes.contentContainer}>
          <Router history={history}>
            <Switch history={history}>
              <PublicRoute path={DefaultRoutes.Root} exact component={HomeView} />
              <PublicRoute path={Routes.ForgotPassword} exact component={ForgotPassword} />
              <PublicRoute path={Routes.CreateAccount} exact component={CreateAccount} />
              <PublicRoute path={Routes.ChangePassword} exact component={ChangePassword} />
              <PublicRoute path={Routes.Policies} exact component={Policies} />
              <RedirectRoute to={DefaultRoutes.Root} />
            </Switch>
          </Router>
        </div>
        <div className={classes.footerContainer}>
          <img src={Images.logoTechnogym} className={classes.logoTechnogym} alt="technogym-logo" />
          <span className={classes.textSmall}>Technogym S.p.A.</span>
        </div>
      </div>
    )
  }
}

export default withTranslation(withStyles(MainViewStyles)(MainView))
