import React, { Component } from 'react'
import { Formik } from 'formik'
import {
  TextField,
  Button,
  WithStyles,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import * as Yup from 'yup'
import CreateAccountStyles from './CreateAccount.styles'
import { withStyles } from '@material-ui/core/styles'
import App from '../../App'
import { getApiKey } from '../../infrastructure/utils/FacilityUtils'
import Routes from '../../infrastructure/routing/Routes'
import { PolicyTypes } from '../policies/Policies'
import { PrivacySettingConfiguration } from '../../infrastructure/repository/models/FacilityPublicProfileResponse'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

export interface CreateAccountProps {
  facilityId: string
  userId: string
  email: string
  checksum: string
}
interface CreateAccountState {
  loading: boolean
  success: boolean
  showPassword: boolean
}

class CreateAccount extends Component<CreateAccountProps & WithStyles<typeof CreateAccountStyles>, CreateAccountState> {
  chainId: string = ''
  facilityUrl: string = ''
  facilityLogo: string | undefined = undefined
  privacySettings: Array<PrivacySettingConfiguration> = []
  privacyPolicyHtml: string = ''
  termsConditionsHtml: string = ''
  validationObject: any = {}
  validationSchema: Yup.ObjectSchema<any> = Yup.object({})

  constructor (props: CreateAccountProps & WithStyles<typeof CreateAccountStyles>) {
    super(props)

    const { i18n } = App

    this.validationObject = {
      email: Yup.string()
        .email(i18n.t('S11'))
        .required(i18n.t('S9')),
      confirmEmail: Yup.string()
        .required(i18n.t('S9'))
        .oneOf([Yup.ref('email')], i18n.t('S10')),
      password: Yup.string()
        .matches(/\w{4,15}/, i18n.t('S12').replace(/(<([^>]+)>)/gi, ''))
        .required(i18n.t('S9')),
      nickname: Yup.string().required(i18n.t('S9'))
    }
    this.validationSchema = Yup.object(this.validationObject)

    this.state = {
      loading: true,
      success: false,
      showPassword: false
    }
  }

  async componentDidMount () {
    await this.getFacilityData()
  }

  getFacilityData = async () => {
    const { repo, i18n } = App
    const { facilityId } = this.props

    let hasErrors = false
    try {
      const facilityPublicProfileResponse = await repo.facilityPublicProfile(facilityId)

      if (!facilityPublicProfileResponse.hasErrors && facilityPublicProfileResponse.data) {
        this.chainId = facilityPublicProfileResponse.data.chainId
        this.facilityUrl = facilityPublicProfileResponse.data.url
        this.facilityLogo = facilityPublicProfileResponse.data.logoUrl
        let privacySettingsConfiguration = facilityPublicProfileResponse.data.privacyPolicy.privacySettingsConfiguration

        privacySettingsConfiguration.forEach(privacySetting => {
          if (privacySetting.isMandatory) {
            this.validationObject[privacySetting.key] = Yup.boolean().oneOf([true], i18n.t('S9'))
          }
        })

        privacySettingsConfiguration = privacySettingsConfiguration.sort(privacySetting => {
          if (privacySetting.isMandatory) return -1
          return 1
        })

        this.validationSchema = Yup.object(this.validationObject)
        this.privacySettings = privacySettingsConfiguration
        this.privacyPolicyHtml = facilityPublicProfileResponse.data.privacyPolicy.privacyPolicyText
        this.termsConditionsHtml = facilityPublicProfileResponse.data.privacyPolicy.termsConditionText

        this.setState({ loading: false })
      } else {
        hasErrors = true
      }
    } catch (err) {
      hasErrors = true
    }

    if (hasErrors) {
      this.setState({ loading: false })
      const { notifier } = App
      notifier.showErrorMessage(i18n.t('S13'))
    }
  }

  onSubmit = async (values: any, actions: any) => {
    const { repo, i18n } = App

    let hasErrors = false
    let errorMessage = i18n.t('S13')

    try {
      const { userId, facilityId, checksum } = this.props
      const apiKey = getApiKey(this.chainId) || ''

      const registerResponse = await repo.register(
        this.facilityUrl,
        apiKey,
        userId,
        values.email,
        values.password,
        values.nickname,
        checksum,
        {
          acceptMarketingTermCondition: true,
          acceptPolicyTermCondition: true,
          acceptSensitiveDataProcessing: true
        }
      )
      if (!registerResponse.hasErrors) {
        const privacySettings: Map<string, boolean> = new Map(
          this.privacySettings.map<[string, boolean]>((privacySetting: PrivacySettingConfiguration) => {
            return [privacySetting.key, values[privacySetting.key]]
          })
        )

        const saveUserPrivacySettingsResponse = await repo.saveUserPrivacySettings(
          this.facilityUrl,
          apiKey,
          registerResponse.data.userId,
          facilityId,
          privacySettings
        )

        if (!saveUserPrivacySettingsResponse.hasErrors) {
          this.setState({
            success: true
          })
        } else {
          hasErrors = true
          errorMessage = saveUserPrivacySettingsResponse.getErrorMessage()
        }
      } else {
        hasErrors = true
        errorMessage = registerResponse.getErrorMessage()
      }
    } catch (err) {
      hasErrors = true
    }

    if (hasErrors) {
      const { notifier } = App
      notifier.showErrorMessage(errorMessage)
    }

    actions.setSubmitting(false)
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  render () {
    const { classes, facilityId, email } = this.props
    const { loading, success, showPassword } = this.state
    const { i18n } = App

    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )
    }

    if (success) {
      return (
        <div className={classes.container}>
          {this.facilityLogo && (
            <div className={classes.logoContainer}>
              <img src={this.facilityLogo} className={classes.logo} alt="logo" />
            </div>
          )}
          <div className={classes.formContainer}>
            <h1 className={classes.title}>{i18n.t('S14')}</h1>
            <span className={classes.subtitle}>{i18n.t('S15')}</span>
          </div>
        </div>
      )
    }

    return (
      <div className={classes.container}>
        {this.facilityLogo && (
          <div className={classes.logoContainer}>
            <img src={this.facilityLogo} className={classes.logo} alt="logo" />
          </div>
        )}
        <div className={classes.formContainer}>
          <h1 className={classes.title}>{i18n.t('S1')}</h1>
          <Formik
            enableReinitialize
            initialValues={{
              email: email,
              confirmEmail: email,
              password: '',
              nickname: '',
              ...this.privacySettings.reduce((obj: any, privacySetting: PrivacySettingConfiguration) => {
                obj[privacySetting.key] = false
                return obj
              }, {})
            }}
            validationSchema={this.validationSchema}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, handleChange, handleBlur, isSubmitting, errors, values, touched }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.formInner}>
                  <div className={classes.formContent}>
                    <div className={classes.formField}>
                      <TextField
                        id="email"
                        name="email"
                        label={i18n.t('S4')}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.email && errors.email ? errors.email : ' '}
                        error={touched.email && Boolean(errors.email)}
                        disabled={isSubmitting}
                        inputProps={{ maxLength: 100 }}
                        value={values.email}
                      />
                    </div>
                    <div className={classes.formField}>
                      <TextField
                        id="confirmEmail"
                        name="confirmEmail"
                        label={i18n.t('S5')}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.confirmEmail && errors.confirmEmail ? errors.confirmEmail : ' '}
                        error={touched.confirmEmail && Boolean(errors.confirmEmail)}
                        disabled={isSubmitting}
                        inputProps={{ maxLength: 100 }}
                        value={values.confirmEmail}
                      />
                    </div>
                    <div className={classes.formField}>
                      <TextField
                        id="password"
                        name="password"
                        label={i18n.t('S6')}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.password && errors.password ? errors.password : ' '}
                        error={touched.password && Boolean(errors.password)}
                        disabled={isSubmitting}
                        InputProps={{
                          type: showPassword ? 'text' : 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={this.handleClickShowPassword}>
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    <div className={classes.formField}>
                      <TextField
                        id="nickname"
                        name="nickname"
                        label={i18n.t('S7')}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.nickname && errors.nickname ? errors.nickname : ' '}
                        error={touched.nickname && Boolean(errors.nickname)}
                        disabled={isSubmitting}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>
                    <p
                      className={classes.policiesText}
                      dangerouslySetInnerHTML={{
                        __html: i18n.t(
                          'S2',
                          true,
                          `${Routes.Policies}?facilityId=${facilityId}&policyType=${PolicyTypes.Privacy}`,
                          `${Routes.Policies}?facilityId=${facilityId}&policyType=${PolicyTypes.TermsOfUse}`
                        )
                      }}
                    />
                    {this.privacySettings.map((privacySetting: PrivacySettingConfiguration) => {
                      return (
                        <div key={privacySetting.key}>
                          <FormControl
                            classes={{ root: classes.policyBlock }}
                            error={touched[privacySetting.key] && Boolean(errors[privacySetting.key])}
                            disabled={isSubmitting}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={privacySetting.key}
                                    name={privacySetting.key}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    classes={{ root: classes.checkbox }}
                                    color="primary"
                                  />
                                }
                                label={`${privacySetting.title}${privacySetting.isMandatory ? ' *' : ''}`}
                              />
                              <FormHelperText>
                                {touched[privacySetting.key] && errors[privacySetting.key]
                                  ? errors[privacySetting.key]
                                  : ' '}
                              </FormHelperText>
                            </FormGroup>
                          </FormControl>
                        </div>
                      )
                    })}
                    <span className={classes.mandatoryText}>* {i18n.t('S8')}</span>
                    <Button
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      classes={{ root: classes.submit, label: classes.submitLabel }}
                    >
                      {isSubmitting ? <CircularProgress size={28} /> : i18n.t('S1')}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default withStyles(CreateAccountStyles)(CreateAccount)
