import Theme from './../themes/Theme'

// width < 600px
export function smallDevices (theme: Theme) {
  return theme.breakpoints.down('xs')
}

// width < 1280px
export function mediumDevices (theme: Theme) {
  return theme.breakpoints.down('lg')
}

// width < 1920px
export function largeDevices (theme: Theme) {
  return theme.breakpoints.down('xl')
}

// width >= 1920px
export function extraLargeDevices (theme: Theme) {
  return theme.breakpoints.up('xl')
}
