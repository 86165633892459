import { HttpHeader, DateFormatType } from 'mw-react-web-app-infrastructure'
import App from '../../App'
import createHmac from 'create-hmac'

const HASH_SECRET = 'mwcsigns'
const HASH_ALG = 'sha256'
const TOKEN_SEPARATOR = '|'

function generateSignatureHeader (apiKey: string): HttpHeader {
  const { i18n } = App
  const date = i18n.date.format(i18n.date.getCurrentUtc(), DateFormatType.ApiDateTime)
  const hmac = createHmac(HASH_ALG, apiKey)
    .update(HASH_SECRET + date)
    .digest('hex')

  const header = { name: 'x-mwapps-authtoken', value: date.concat(TOKEN_SEPARATOR, hmac) }

  return header
}

export { generateSignatureHeader }
