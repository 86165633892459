import React, { Component } from 'react'
import App from '../../App'
import { CircularProgress, WithStyles, withStyles } from '@material-ui/core'
import PoliciesStyles from './Policies.styles'

export enum PolicyTypes {
  Privacy = 'privacy',
  TermsOfUse = 'termsofuse'
}

export interface PoliciesProps {
  facilityId: string
  policyType: PolicyTypes
}
interface PoliciesState {
  loading: boolean
  html: string
}

class Policies extends Component<PoliciesProps & WithStyles<typeof PoliciesStyles>, PoliciesState> {
  constructor (props: PoliciesProps & WithStyles<typeof PoliciesStyles>) {
    super(props)

    this.state = {
      loading: true,
      html: ''
    }
  }

  async componentDidMount () {
    await this.getFacilityData()
  }

  getFacilityData = async () => {
    const { repo } = App
    const { facilityId, policyType } = this.props

    let hasErrors = false
    try {
      const facilityPublicProfileResponse = await repo.facilityPublicProfile(facilityId)

      if (!facilityPublicProfileResponse.hasErrors && facilityPublicProfileResponse.data) {
        let html = ''

        switch (policyType) {
          case PolicyTypes.Privacy:
            html = facilityPublicProfileResponse.data.privacyPolicy.privacyPolicyText
            break
          case PolicyTypes.TermsOfUse:
            html = facilityPublicProfileResponse.data.privacyPolicy.termsConditionText
            break
        }

        this.setState({
          html,
          loading: false
        })
      } else {
        hasErrors = true
      }
    } catch (err) {
      hasErrors = true
    }

    if (hasErrors) {
    }
  }

  render () {
    const { loading, html } = this.state
    const { classes } = this.props

    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )
    }

    return <div className={classes.container} dangerouslySetInnerHTML={{ __html: html }} />
  }
}

export default withStyles(PoliciesStyles)(Policies)
