import { createStyles } from '@material-ui/core/styles'
import Theme from './../../assets/styles/themes/Theme'
import { smallDevices } from './../../assets/styles/mixins/Breakpoints'

const MainViewStyles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr auto'
    },
    contentContainer: {
      gridRow: '1 / 1'
    },
    footerContainer: {
      gridRow: '2 / 2',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: '8px 0',
      justifySelf: 'start',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    logoTechnogym: {
      height: 16,
      width: 'auto',
      marginLeft: 50,
      marginRight: 20
    },
    textSmall: {
      ...theme.typography.small
    },
    [smallDevices(theme)]: {
      container: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr auto'
      },
      navContainer: {
        gridColumn: '1 / 1',
        gridRow: '2 / 2',
        width: '100%'
      },
      contentContainer: {
        gridColumn: '1 / 1',
        gridRow: '1 / 1'
      },
      footerContainer: {
        display: 'none'
      }
    }
  })

export default MainViewStyles
