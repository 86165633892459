import React, { Component } from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import App from '../../../App'
import MainView from '../../main/MainView'
import SplashScreenViewStyles from './SplashScreen.styles'

// This component is used to initialize application
// and must not have localized strings because ReactI18nextService
// has not been initialized yet.
interface SplashScreenViewProps {}
interface SplashScreenViewState {
  loading: boolean
  appInitialized: boolean
}

class SplashScreenView extends Component<
  SplashScreenViewProps & WithStyles<typeof SplashScreenViewStyles>,
  SplashScreenViewState
  > {
  constructor (props: SplashScreenViewProps & WithStyles<typeof SplashScreenViewStyles>) {
    super(props)

    this.state = {
      loading: true,
      appInitialized: false
    }
  }

  async componentDidMount () {
    const appInitialized = await App.initialize()

    this.setState({ loading: false, appInitialized: appInitialized })
  }

  render () {
    const { loading, appInitialized } = this.state
    const { classes } = this.props

    return loading ? (
      <div className={classes.container}>
        <CircularProgress />
      </div>
    ) : appInitialized ? (
      <MainView />
    ) : (
      <div>An error occured. Please try again</div>
    )
  }
}

export default withStyles(SplashScreenViewStyles)(SplashScreenView)
