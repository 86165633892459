import React from 'react'
import ReactDOM from 'react-dom'
import HttpsRedirect from 'react-https-redirect'
import * as serviceWorker from './serviceWorker'
import ThemeDefault from './assets/styles/themes/ThemeDefault'
import { ThemeProvider } from '@material-ui/styles'
import SplashScreen from './views/infrastructure/splash-screen/SplashScreen'

ReactDOM.render(
  <HttpsRedirect>
    <ThemeProvider theme={ThemeDefault}>
      <SplashScreen />
    </ThemeProvider>
  </HttpsRedirect>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
