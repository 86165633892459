// TODO: move Notifier in UI package
import React, { Component } from 'react'
import {
  NotificationMessageEnqueuedEventArgs,
  NotificationMessage,
  HideNotificationMessageEnqueuedEventArgs
} from 'mw-react-web-app-infrastructure'
import { Snackbar, Button, IconButton, SnackbarContent } from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import clsx from 'clsx'
import App from './../../App'
import NotifierStyles from './Notifier.styles'

class NotificationMessageQueueItem {
  key: string
  message: NotificationMessage

  constructor (key: string, message: NotificationMessage) {
    this.key = key
    this.message = message
  }
}

const notificationIcons: any = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  default: null
}

interface NotifierProps {}
interface NotifierState {
  open: boolean
  notification?: NotificationMessageQueueItem
}

class Notifier extends Component<NotifierProps & WithStyles<typeof NotifierStyles>, NotifierState> {
  notificationMessageEnqueuedEventToken?: string
  hideNotificationMessageEnqueuedEventToken?: string
  notificationMessageQueue: NotificationMessageQueueItem[] = []

  constructor (props: NotifierProps & WithStyles<typeof NotifierStyles>) {
    super(props)

    this.state = {
      open: false
    }

    this.onNotificationMessageEnqueued = this.onNotificationMessageEnqueued.bind(this)
    this.onHideNotificationMessageEnqueued = this.onHideNotificationMessageEnqueued.bind(this)
    this.handleNotificationClose = this.handleNotificationClose.bind(this)
    this.handleNotificationExited = this.handleNotificationExited.bind(this)
    this.handleNotificationAction = this.handleNotificationAction.bind(this)
  }

  componentDidMount () {
    const { events } = App

    this.notificationMessageEnqueuedEventToken = events.subscribeNotificationMessageEnqueued(
      this.onNotificationMessageEnqueued
    )
    this.hideNotificationMessageEnqueuedEventToken = events.subscribeHideNotificationMessageEnqueued(
      this.onHideNotificationMessageEnqueued
    )
  }

  componentWillUnmount () {
    const { events } = App

    if (this.notificationMessageEnqueuedEventToken) {
      events.unsubscribe(this.notificationMessageEnqueuedEventToken)
    }
    if (this.hideNotificationMessageEnqueuedEventToken) {
      events.unsubscribe(this.hideNotificationMessageEnqueuedEventToken)
    }
  }

  onNotificationMessageEnqueued (eventArgs: NotificationMessageEnqueuedEventArgs) {
    this.showNotification(eventArgs.message)
  }

  onHideNotificationMessageEnqueued (eventArgs: HideNotificationMessageEnqueuedEventArgs) {
    this.hideNotification()
  }

  showNotification (message: NotificationMessage) {
    this.notificationMessageQueue.push(new NotificationMessageQueueItem(new Date().getTime().toString(), message))

    const { open } = this.state

    if (open) {
      // Immediately begin dismissing current message to start showing new one.
      this.setState({ open: false })
    } else {
      this.processQueue()
    }
  }

  hideNotification () {
    this.setState({ open: false })
  }

  handleNotificationClose (event: React.FormEvent<HTMLFormElement>, reason: string) {
    if (reason === 'clickaway') {
      return
    }

    this.hideNotification()
  }

  handleNotificationExited () {
    this.processQueue()
  }

  handleNotificationAction (action: () => void) {
    this.hideNotification()

    if (action) {
      action()
    }
  }

  processQueue () {
    if (this.notificationMessageQueue.length > 0) {
      this.setState({
        notification: this.notificationMessageQueue.shift(),
        open: true
      })
    }
  }

  render () {
    const { open, notification } = this.state
    if (notification) {
      const { classes } = this.props
      const { message } = notification
      const autoHideDuration =
        message.options && message.options.autoHide ? message.options.autoHideDuration : undefined
      const title = message.options && message.options.title ? message.options.title : null
      const text = message.text
      const Icon = notificationIcons[message.type]
      let actions = []

      if (message.options) {
        if (message.options.action) {
          const { action } = message.options

          actions.push(
            <Button
              key="action"
              color="inherit"
              size="small"
              onClick={() => this.handleNotificationAction(action.handler)}
            >
              {action.text}
            </Button>
          )
        }

        if (message.options.showCloseButton) {
          actions.push(
            <IconButton key="close" aria-label="Close" color="inherit" onClick={() => this.hideNotification()}>
              <CloseIcon />
            </IconButton>
          )
        }
      }

      return (
        <Snackbar
          key={notification.key}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={this.handleNotificationClose}
          onExited={this.handleNotificationExited}
          disableWindowBlurListener
        >
          <SnackbarContent
            aria-describedby="message-id"
            className={clsx(classes.container, message.type)}
            message={
              <div id="message-id" className={classes.contentContainer}>
                {Icon && <Icon className={classes.icon} />}
                <div className={classes.messageContainer}>
                  {title && <div className={classes.messageTitle}>{title}</div>}
                  <div>{text}</div>
                </div>
              </div>
            }
            action={actions}
          />
        </Snackbar>
      )
    } else {
      return null
    }
  }
}

export default withStyles(NotifierStyles)(Notifier)
