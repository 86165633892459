import { createMuiTheme } from '@material-ui/core/styles'
import createPalette, { Palette } from '@material-ui/core/styles/createPalette'
import createTypography from '@material-ui/core/styles/createTypography'

const TgColors = {
  black: '#0d0d0d',
  blackFade50: '#868686',
  silver: '#cccccc',
  dirtyWhite: '#f3f3f3',
  yellow: '#ffe01e',
  white: '#ffffff',
  red: '#f52d46',
  orange: '#f3a226',
  green: '#63a619'
}

const palette: Palette = createPalette({
  background: {
    default: TgColors.dirtyWhite,
    paper: TgColors.white
  },
  text: {
    primary: TgColors.black
  },
  primary: {
    main: TgColors.black,
    contrastText: TgColors.white
  },
  secondary: {
    main: TgColors.blackFade50
  },
  success: {
    main: TgColors.green,
    light: TgColors.green,
    dark: TgColors.green,
    contrastText: TgColors.white
  },
  info: {
    main: TgColors.white,
    light: TgColors.white,
    dark: TgColors.white,
    contrastText: TgColors.black
  },
  warning: {
    main: TgColors.orange,
    light: TgColors.orange,
    dark: TgColors.orange,
    contrastText: TgColors.white
  },
  error: {
    main: TgColors.red,
    light: TgColors.red,
    dark: TgColors.red,
    contrastText: TgColors.white
  }
})

const typography = createTypography(palette, {
  fontSize: 16,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Roboto"',
    '"Oxygen"',
    '"Ubuntu"',
    '"Cantarell"',
    '"Fira Sans"',
    '"Droid Sans"',
    '"Helvetica Neue"',
    '"Arial"',
    '"sans-serif"'
  ].join(','),
  heading: {
    fontSize: 32,
    fontWeight: 900,
    color: palette.text.primary
  },
  small: {
    fontSize: 10,
    fontWeight: 500,
    color: palette.text.primary
  },
  navBold: {
    fontSize: 14,
    fontWeight: 900,
    color: palette.text.primary
  }
})

const ThemeDefault = createMuiTheme({
  palette,
  typography,
  overrides: {
    MuiButtonBase: {
      root: {
        borderRadius: 3,
        paddingTop: 10,
        paddingBottom: 10
      }
    }
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none'
  ]
})

export default ThemeDefault
