import {
  AppBase,
  ConsoleLoggingService,
  LocalStorageService,
  LruCachingService,
  FetchHttpService,
  CurrentDeviceService,
  MomentDatesManipulationService,
  NumbroNumbersManipulationService,
  ReactI18nextService,
  DeviceType,
  DeviceOrientationChangedEventArgs,
  SessionRestoredEventArgs,
  GoogleAnalyticsTrackingService,
  EnvironmentType,
  EventNotificationService,
  DotEnvAppConfiguration,
  PubSubEventAggregatorService,
  StorageSessionService
} from 'mw-react-web-app-infrastructure'

import RoutingService from './infrastructure/routing/RoutingService'
import RepositoryService from './infrastructure/repository/RepositoryService'
import { i18nCultureFilesVersion } from './i18nCache'

class App
implements
AppBase<
DotEnvAppConfiguration,
ConsoleLoggingService,
LocalStorageService,
StorageSessionService,
LruCachingService,
FetchHttpService,
RepositoryService,
PubSubEventAggregatorService,
ReactI18nextService,
CurrentDeviceService,
RoutingService,
GoogleAnalyticsTrackingService,
EventNotificationService
> {
  private static instance: App
  public name: string
  public version: string
  public config: DotEnvAppConfiguration
  public logger!: ConsoleLoggingService
  public storage!: LocalStorageService
  public session!: StorageSessionService
  public cache!: LruCachingService
  public http!: FetchHttpService
  public repo!: RepositoryService
  public events!: PubSubEventAggregatorService
  public i18n!: ReactI18nextService
  public device!: CurrentDeviceService
  public router!: RoutingService
  public tracker!: GoogleAnalyticsTrackingService
  public notifier!: EventNotificationService

  // eslint-disable-next-line
  private constructor(name: string, version: string, config: DotEnvAppConfiguration) {
    this.name = name
    this.version = version
    this.config = config || new DotEnvAppConfiguration()
    this.onSessionRestored = this.onSessionRestored.bind(this)
    this.onDeviceOrientationChanged = this.onDeviceOrientationChanged.bind(this)
  }

  // Singleton implementation
  public static getInstance () {
    if (!App.instance) {
      const appName: string = process.env.REACT_APP_NAME || 'enduserweb'
      const appVersion: string = process.env.REACT_APP_VERSION || '1.0'
      const config = new DotEnvAppConfiguration()

      App.instance = new App(appName, appVersion, config)
    }

    return App.instance
  }

  public async initialize (): Promise<boolean> {
    let appInitialized = false

    try {
      appInitialized = await this.configureServices()

      if (appInitialized) {
        this.logger.debug(
          'App initialized.',
          this.config,
          this.logger,
          this.device,
          this.storage,
          this.session,
          this.cache,
          this.http,
          this.repo,
          this.events,
          this.i18n,
          this.router,
          this.tracker,
          this.notifier
        )
      } else {
        this.logError('An error occured initializing app.')
      }
    } catch (error) {
      this.logError('An error occured initializing app.')
    }

    return appInitialized
  }

  private async configureServices (): Promise<boolean> {
    let result = true

    // TODO: some service can be called at the same time
    // with Promise.all to improve performance.
    result = result && (await this.configureLoggingService())
    result = result && (await this.configureEventAggregatorService())
    result = result && (await this.configureNotificationService())
    result = result && (await this.configureTrackingService())
    result = result && (await this.configureDeviceService())
    result = result && (await this.configureI18nService())
    result = result && (await this.configureStorageService())
    result = result && (await this.configureSessionService())
    result = result && (await this.configureCacheService())
    result = result && (await this.configureHttpService())
    result = result && (await this.configureRepositoryService())
    result = result && (await this.configureRoutingService())

    return result
  }

  private async configureLoggingService (): Promise<boolean> {
    this.logger = new ConsoleLoggingService({ level: this.config.loggingLevel })

    return await this.logger.initialize()
  }

  private async configureEventAggregatorService (): Promise<boolean> {
    this.events = new PubSubEventAggregatorService()

    const result = await this.events.initialize(this.logger)

    if (result) {
      this.events.subscribeSessionRestored(this.onSessionRestored)
      this.events.subscribeDeviceOrientationChanged(this.onDeviceOrientationChanged)
    }

    return result
  }

  private async configureNotificationService (): Promise<boolean> {
    this.notifier = new EventNotificationService({ autoHide: true, autoHideDuration: 7000 })

    const result = await this.notifier.initialize(this.events, this.logger)

    return result
  }

  private async configureTrackingService (): Promise<boolean> {
    this.tracker = new GoogleAnalyticsTrackingService({
      trackingId: this.config.trackingGoogleAnalyticsId,
      enabled: this.config.trackingEnabled,
      anonymizeIP: true,
      debug: this.config.environment === EnvironmentType.Debug
    })

    return await this.tracker.initialize(this.logger)
  }

  private async configureDeviceService (): Promise<boolean> {
    this.device = new CurrentDeviceService()

    return await this.device.initialize(this.events, this.logger)
  }

  private async configureI18nService (): Promise<boolean> {
    const culture = this.getDeviceCulture()
    this.i18n = new ReactI18nextService({
      culture,
      supportedCultures: this.config.supportedCultures,
      fallbackCulture: this.config.fallbackCulture,
      cultureFilesPath: this.config.cultureFilesPath,
      cultureFilesVersion: i18nCultureFilesVersion,
      cultureFilesCacheEnabled: this.config.cultureFilesCacheEnabled,
      cultureFilesCacheExpiration: this.config.cultureFilesCacheExpiration
    })

    return await this.i18n.initialize(
      new MomentDatesManipulationService(culture),
      new NumbroNumbersManipulationService(culture),
      this.events,
      this.session,
      this.logger
    )
  }

  private async configureStorageService (): Promise<boolean> {
    this.storage = new LocalStorageService()

    return await this.storage.initialize(this.logger)
  }

  private async configureCacheService (): Promise<boolean> {
    this.cache = new LruCachingService({
      maxSize: this.config.cacheMaxSize
    })

    return await this.cache.initialize(this.logger)
  }

  private async configureSessionService (): Promise<boolean> {
    this.session = new StorageSessionService({ keepUserLoggedIn: this.device.type === DeviceType.Mobile })

    return await this.session.initialize(this.storage, this.logger)
  }

  private async configureHttpService (): Promise<boolean> {
    this.http = new FetchHttpService({ requestTimeout: this.config.httpRequestTimeout })

    return await this.http.initialize(this.logger)
  }

  private async configureRepositoryService (): Promise<boolean> {
    this.repo = new RepositoryService({
      appVersion: this.version,
      apiAppId: this.config.apiAppId,
      apiAppName: this.config.apiAppName,
      apiUrl: this.config.apiUrl,
      culture: this.getDeviceCulture()
    })

    return await this.repo.initialize(this.http, this.session, this.cache, this.events, this.logger)
  }

  private async configureRoutingService (): Promise<boolean> {
    this.router = new RoutingService({
      trackRoutes: this.config.trackingTrackRoutes,
      forceRefresh: false
    })

    return await this.router.initialize(this.events, this.tracker, this.logger)
  }

  private async onSessionRestored (eventArgs: SessionRestoredEventArgs): Promise<void> {
    if (this.i18n) {
      await this.i18n.changeCulture(this.session.user.culture)
    }
  }

  private onDeviceOrientationChanged (eventArgs: DeviceOrientationChangedEventArgs): void {
    this.logger.info(`Device orientation changed to ${eventArgs.orientation}.`)
  }

  public getDeviceCulture (): string {
    // TODO: First check if culture is passed in query string, then get it from device.
    // Device culture may not be in <language>-<country> format, so get a valid culture.
    return ReactI18nextService.getValidCulture(
      this.device.culture,
      this.config.supportedCultures,
      this.config.fallbackCulture
    )
  }

  private logError (message: string, error?: any) {
    if (this.logger) {
      this.logger.error(message, error)
    } else {
      console.error(message, error)
    }
  }
}

export default App.getInstance()
