import { createStyles } from '@material-ui/core/styles'
import Theme from './themes/Theme'

const formWidth = 450

const CommonStyles = (theme: Theme) =>
  createStyles({
    logoContainer: {
      justifySelf: 'start',
      marginLeft: 50,
      padding: '20px 0'
    },
    formContainer: {
      justifySelf: 'stretch',
      marginLeft: 130,
      marginTop: 100,
      marginBottom: 100,
      maxWidth: formWidth,
      padding: '20px 20px 40px'
    },
    formContent: {
      marginTop: 30,
      width: formWidth
    },
    logo: {
      width: 110
    },
    formField: {
      marginBottom: 16
    },
    title: {
      margin: '0 0 20px',
      ...theme.typography.heading
    },
    subtitle: {
      marginBottom: 30
    },
    submit: {
      marginTop: 20
    },
    submitLabel: {
      fontWeight: 'bold'
    },
    mandatoryText: {
      ...theme.typography.navBold,
      display: 'block',
      marginBottom: 20
    },
    errorText: {
      display: 'block',
      marginTop: 20,
      color: theme.palette.error.main
    },
    errorPlaceholder: {
      height: 38
    },
    smallLogoContainer: {
      justifySelf: 'center',
      marginLeft: 0
    },
    smallFormContainer: {
      alignSelf: 'start',
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 0,
      maxWidth: '100%'
    },
    smallFormContent: {
      width: '100%'
    }
  })

export default CommonStyles
