import { createStyles } from '@material-ui/core/styles'
import Theme from './themes/Theme'

const GlobalStyles = (theme: Theme) =>
  createStyles({
    '@global': {
      'html, body, body #root': {
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0
      },
      body: {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale'
      },
      touch: {
        /*
        It's really annoying when a user trying to interact with an element causes the element, or the text within it, to be selected instead.
        Adding user-select: none to everything, except for the things that a user would genuinely want to copy paste can cut way down
        on these interactions being accidentally triggered.
        */
        '-webkit-user-select': 'none' /* Chrome all / Safari all */,
        '-moz-user-select': 'none' /* Firefox all */,
        '-ms-user-select': 'none' /* IE 10+ */,
        'user-select': 'none' /* Likely future */,

        /*
        Adding -webkit-touch-callout: none; to an element prevents a tap and hold from opening a context menu on the link or image.
        On Android, to stop these menu's from showing up, you can do:
        if(navigator.userAgent.match(/Android/i)) {
          noContextMenu.addEventListener('contextmenu', function (e) { e.preventDefault() })
        }
        */
        '-webkit-touch-callout': 'none',

        /*
        By default, mobile web browsers display a tap highlight, so that users get feedback when they tap something.
        Unfortunately it looks awful and is a dead giveaway that your app isn't native.
        The really easy solution is to add this to your css. You NEVER want the default highlight.
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        */
        '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)'
      },
      a: {
        color: theme.palette.text.primary
      }
    }
  })

export default GlobalStyles
