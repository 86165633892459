import { createStyles } from '@material-ui/core/styles'
import Theme from './../../assets/styles/themes/Theme'

const NotifierStyles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      '&.default': {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText
      },
      '&.info': {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText
      },
      '&.success': {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText
      },
      '&.warning': {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText
      },
      '&.error': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
      }
    },
    contentContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr'
    },
    icon: {
      gridColumn: '1 / 1',
      alignSelf: 'center',
      justifySelf: 'center',
      marginRight: 16
    },
    messageContainer: {
      gridColumn: '2 / 2'
    },
    messageTitle: {
      fontWeight: 'bold',
      marginBottom: 2
    }
  })

export default NotifierStyles
