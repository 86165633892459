import React, { Component } from 'react'
import App from '../../App'
import { withStyles } from '@material-ui/core/styles'
import ForgotPasswordStyles from './ForgotPassword.styles'
import { CircularProgress, Button, TextField, WithStyles } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { getApiKey } from '../../infrastructure/utils/FacilityUtils'

export interface ForgotPasswordProps {
  facilityId: string
}
interface ForgotPasswordState {
  loading: boolean
  success: boolean
}

class ForgotPassword extends Component<
  ForgotPasswordProps & WithStyles<typeof ForgotPasswordStyles>,
  ForgotPasswordState
  > {
  chainId: string = ''
  facilityUrl: string = ''
  facilityLogo: string | undefined = undefined
  validationSchema: Yup.ObjectSchema<any> = Yup.object({})

  constructor (props: ForgotPasswordProps & WithStyles<typeof ForgotPasswordStyles>) {
    super(props)

    const { i18n } = App

    this.validationSchema = Yup.object({
      email: Yup.string()
        .email(i18n.t('S11'))
        .required(i18n.t('S9'))
    })

    this.state = {
      loading: true,
      success: false
    }
  }

  async componentDidMount () {
    await this.getFacilityData()
  }

  getFacilityData = async () => {
    const { repo, i18n } = App
    const { facilityId } = this.props

    let hasErrors = false
    try {
      const facilityPublicProfileResponse = await repo.facilityPublicProfile(facilityId)

      if (!facilityPublicProfileResponse.hasErrors && facilityPublicProfileResponse.data) {
        this.chainId = facilityPublicProfileResponse.data.chainId
        this.facilityUrl = facilityPublicProfileResponse.data.url
        this.facilityLogo = facilityPublicProfileResponse.data.logoUrl

        this.setState({ loading: false })
      } else {
        hasErrors = true
      }
    } catch (err) {
      hasErrors = true
    }

    if (hasErrors) {
      this.setState({ loading: false })
      const { notifier } = App
      notifier.showErrorMessage(i18n.t('S13'))
    }
  }

  onSubmit = async (values: any, actions: any) => {
    const { repo, i18n } = App

    let hasErrors = false
    let errorMessage = i18n.t('S13')

    try {
      const apiKey = getApiKey(this.chainId) || ''

      const forgotPasswordResponse = await repo.forgotPassword(this.facilityUrl, apiKey, values.email)

      if (!forgotPasswordResponse.hasErrors) {
        this.setState({
          success: true
        })
      } else {
        hasErrors = true
        errorMessage = forgotPasswordResponse.getErrorMessage()
      }
    } catch (err) {
      hasErrors = true
    }

    if (hasErrors) {
      const { notifier } = App
      notifier.showErrorMessage(errorMessage)
    }

    actions.setSubmitting(false)
  }

  render () {
    const { i18n } = App
    const { classes } = this.props
    const { loading, success } = this.state

    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )
    }

    if (success) {
      return (
        <div className={classes.container}>
          {this.facilityLogo && (
            <div className={classes.logoContainer}>
              <img src={this.facilityLogo} className={classes.logo} alt="logo" />
            </div>
          )}
          <div className={classes.formContainer}>
            <h1 className={classes.title}>{i18n.t('S16')}</h1>
            <span className={classes.subtitle}>{i18n.t('S25')}</span>
          </div>
        </div>
      )
    }

    return (
      <div className={classes.container}>
        {this.facilityLogo && (
          <div className={classes.logoContainer}>
            <img src={this.facilityLogo} className={classes.logo} alt="logo" />
          </div>
        )}
        <div className={classes.formContainer}>
          <h1 className={classes.title}>{i18n.t('S16')}</h1>
          <span className={classes.subtitle}>{i18n.t('S17')}</span>
          <Formik
            enableReinitialize
            initialValues={{
              email: ''
            }}
            validationSchema={this.validationSchema}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, handleChange, handleBlur, isSubmitting, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.formInner}>
                  <div className={classes.formContent}>
                    <div className={classes.formField}>
                      <TextField
                        id="email"
                        name="email"
                        label="Email"
                        fullWidth
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.email && errors.email ? errors.email : ' '}
                        error={touched.email && Boolean(errors.email)}
                        inputProps={{ type: '' }}
                      />
                    </div>
                    <Button
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      classes={{ root: classes.submit, label: classes.submitLabel }}
                    >
                      {isSubmitting ? <CircularProgress size={28} /> : i18n.t('S18')}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default withStyles(ForgotPasswordStyles)(ForgotPassword)
