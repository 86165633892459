import { ReactRouterRoutingService } from 'mw-react-web-app-infrastructure'
import Routes from './Routes'
import { ForgotPasswordProps } from '../../views/forgot-password/ForgotPassword'
import { CreateAccountProps } from '../../views/create-account/CreateAccount'
import { PoliciesProps } from '../../views/policies/Policies'
import { ChangePasswordProps } from '../../views/change-password/ChangePassword'

class RoutingService extends ReactRouterRoutingService {
  public goToForgotPassword (params: ForgotPasswordProps) {
    this.goTo(Routes.ForgotPassword, params)
  }

  public goToCreateAccount (params: CreateAccountProps) {
    this.goTo(Routes.CreateAccount, params)
  }

  public goToPolicies (params: PoliciesProps) {
    this.goTo(Routes.Policies, params)
  }

  public goToChangePassword (params: ChangePasswordProps) {
    this.goTo(Routes.ChangePassword, params)
  }
}

export default RoutingService
