import { createStyles } from '@material-ui/core'
import { grid, GridAlignItems, GridJustifyItems } from '../../assets/styles/mixins/Layout'
import Theme from './../../assets/styles/themes/Theme'

const PoliciesStyles = (theme: Theme) =>
  createStyles({
    loaderContainer: {
      ...grid('1fr', '1fr', GridAlignItems.Center, GridJustifyItems.Center)
    },
    container: {
      padding: 40
    }
  })

export default PoliciesStyles
