import App from '../../App'

// Map ChainIds to Private Account ApiKeys
const FacilityMapping: Map<string, Map<string, string>> = new Map([
  [
    'debug',
    new Map([
      ['a85135f7-2766-4915-916e-9aab0467b28e', 'f00b0c6d-0680-4fe1-949d-066960f9d5c0'],
      ['aa700c9d-4a60-45c2-a8d2-8e7501bf3a81', '81746d66-b9fa-4805-a78c-837777e46450'],
      ['42424f7c-1a71-4857-b2fa-94f9d907a890', '6e73f9ac-fd49-47a2-cc9a-ae850f77d1b4']
    ])
  ],
  ['development', new Map([['a85135f7-2766-4915-916e-9aab0467b28e', 'f00b0c6d-0680-4fe1-949d-066960f9d5c0']])],
  [
    'test',
    new Map([
      ['42424f7c-1a71-4857-b2fa-94f9d907a890', '6e73f9ac-fd49-47a2-cc9a-ae850f77d1b4'],
      ['aa700c9d-4a60-45c2-a8d2-8e7501bf3a81', '81746d66-b9fa-4805-a78c-837777e46450']
    ])
  ],
  ['production', new Map([['a85135f7-2766-4915-916e-9aab0467b28e', 'f00b0c6d-0680-4fe1-949d-066960f9d5c0']])]
])

function getApiKey (facilityId: string): string | undefined {
  const { config } = App
  const facilityConfig = FacilityMapping.get(config.environment)
  return facilityConfig ? facilityConfig.get(facilityId) : undefined
}

export { getApiKey }
