import { createStyles } from '@material-ui/core/styles'
import Theme from './../../../assets/styles/themes/Theme'
import GlobalStyles from '../../../assets/styles/Global.styles'
import { grid } from '../../../assets/styles/mixins/Layout'

const SplashScreenViewStyles = (theme: Theme) =>
  createStyles({
    ...GlobalStyles(theme),
    container: { ...grid() }
  })

export default SplashScreenViewStyles
