import FacilityPublicProfileResponse from '../models/FacilityPublicProfileResponse'

class ResponseMapper {
  static mapFacilityPublicProfileResponse (data: any): FacilityPublicProfileResponse {
    return {
      url: data.url,
      logoUrl: data.logoUrl,
      chainId: data.chainFacilityId,
      privacyPolicy: data.privacyPolicy
    }
  }
}

export default ResponseMapper
