import {
  MywellnessCloudRepositoryService,
  RepositoryResponse,
  HttpMethodType,
  RepositoryRequestOptions
} from 'mw-react-web-app-infrastructure'
import { generateSignatureHeader } from '../utils/SignatureUtils'
import { strMapToObj } from '../utils/InfrastructureUtils'
import FacilityPublicProfileResponse from './models/FacilityPublicProfileResponse'
import ResponseMapper from './mappers/ResponseMapper'

class RepositoryService extends MywellnessCloudRepositoryService {
  public async facilityPublicProfile (facilityId: string): Promise<RepositoryResponse<FacilityPublicProfileResponse>> {
    const httpRequestUrl = `${this.options.apiUrl}/${facilityId}/publicprofile`
    const httpRequestContent: any = {}
    const httpRequestOptions: RepositoryRequestOptions = {
      responseIsInBody: true
    }

    return await this.send<any, FacilityPublicProfileResponse>(
      {
        method: HttpMethodType.Get,
        url: httpRequestUrl,
        content: httpRequestContent,
        options: httpRequestOptions
      },
      ResponseMapper.mapFacilityPublicProfileResponse
    )
  }

  public async forgotPassword (facilityUrl: string, apiKey: string, email: string): Promise<RepositoryResponse<any>> {
    const httpRequestUrl = `${this.options.apiUrl}/${facilityUrl}/forgotpassword`
    const httpRequestContent = { email }
    const httpRequestOptions: RepositoryRequestOptions = {
      responseIsInBody: true,
      headers: [generateSignatureHeader(apiKey), { name: 'x-mwapps-apikey', value: apiKey }]
    }

    return this.send<any, any>(
      { method: HttpMethodType.Post, url: httpRequestUrl, content: httpRequestContent, options: httpRequestOptions },
      data => {
        return data
      }
    )
  }

  public async changePassword (
    facilityUrl: string,
    apiKey: string,
    email: string,
    oneTimeToken: string,
    newPassword: string
  ): Promise<RepositoryResponse<any>> {
    const httpRequestUrl = `${this.options.apiUrl}/${facilityUrl}/changepassword`
    const httpRequestContent = { email, oneTimeToken, newPassword }
    const httpRequestOptions: RepositoryRequestOptions = {
      responseIsInBody: true,
      headers: [generateSignatureHeader(apiKey), { name: 'x-mwapps-apikey', value: apiKey }]
    }

    return this.send<any, any>(
      { method: HttpMethodType.Post, url: httpRequestUrl, content: httpRequestContent, options: httpRequestOptions },
      data => {
        return data
      }
    )
  }

  public async register (
    facilityUrl: string,
    apiKey: string,
    userId: string,
    email: string,
    password: string,
    nickname: string,
    checksum: string,
    claims: any
  ): Promise<RepositoryResponse<any>> {
    const httpRequestUrl = `${this.options.apiUrl}/${facilityUrl}/register`
    const httpRequestContent = { userId, email, password, nickname, claims, checksum }
    const httpRequestOptions: RepositoryRequestOptions = {
      responseIsInBody: true,
      headers: [generateSignatureHeader(apiKey), { name: 'x-mwapps-apikey', value: apiKey }]
    }

    return await this.send<any, any>(
      { method: HttpMethodType.Post, url: httpRequestUrl, content: httpRequestContent, options: httpRequestOptions },
      data => {
        return data
      }
    )
  }

  public async saveUserPrivacySettings (
    facilityUrl: string,
    apiKey: string,
    userId: string,
    facilityId: string,
    privacySettings: Map<string, boolean>
  ): Promise<RepositoryResponse<any>> {
    const httpRequestUrl = `${this.options.apiUrl}/${facilityUrl}/userprivacysettings`
    const httpRequestContent = { userId, facilityId, privacySettings: strMapToObj(privacySettings) }
    const httpRequestOptions: RepositoryRequestOptions = {
      responseIsInBody: true,
      headers: [generateSignatureHeader(apiKey), { name: 'x-mwapps-apikey', value: apiKey }]
    }

    return await this.send<any, any>(
      { method: HttpMethodType.Post, url: httpRequestUrl, content: httpRequestContent, options: httpRequestOptions },
      data => {
        return data
      }
    )
  }
}

export default RepositoryService
