import Theme from '../../assets/styles/themes/Theme'
import { createStyles } from '@material-ui/core/styles'
import { smallDevices } from '../../assets/styles/mixins/Breakpoints'
import { grid, GridAlignItems, GridJustifyItems } from '../../assets/styles/mixins/Layout'
import CommonStyles from '../../assets/styles/Common.styles'

const CreateAccountStyles = (theme: Theme) => {
  const commonStyles = CommonStyles(theme)

  return createStyles({
    loaderContainer: {
      ...grid('1fr', '1fr', GridAlignItems.Center, GridJustifyItems.Center)
    },
    container: {
      ...grid('auto 1fr auto')
    },
    logoContainer: {
      ...commonStyles.logoContainer
    },
    logo: {
      ...commonStyles.logo
    },
    formContainer: {
      ...commonStyles.formContainer
    },
    formContent: {
      ...commonStyles.formContent
    },
    formInner: {
      ...grid('1fr auto', undefined, GridAlignItems.Center, GridJustifyItems.Start)
    },
    formField: {
      ...commonStyles.formField
    },
    title: {
      ...commonStyles.title
    },
    subtitle: {
      ...commonStyles.subtitle
    },
    submit: {
      ...commonStyles.submit
    },
    submitLabel: {
      ...commonStyles.submitLabel
    },
    policiesText: {
      marginTop: 25,
      marginBottom: 25
    },
    checkbox: {
      justifySelf: 'flex-start'
    },
    policyBlock: {
      marginBottom: 10
    },
    mandatoryText: {
      ...commonStyles.mandatoryText
    },
    [smallDevices(theme)]: {
      logoContainer: {
        ...commonStyles.smallLogoContainer
      },
      formContainer: {
        ...commonStyles.smallFormContainer
      },
      formContent: {
        ...commonStyles.smallFormContent
      }
    }
  })
}

export default CreateAccountStyles
