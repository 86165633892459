import { CSSProperties } from '@material-ui/core/styles/withStyles'

/* CSS Grid */
export enum GridAlignItems {
  // Aligns items to be flush with the start edge of their cell.
  Start = 'start',
  // Aligns items to be flush with the end edge of their cell.
  End = 'end',
  // Aligns items in the center of their cell.
  Center = 'center',
  // Fills the whole height of the cell.
  Stretch = 'stretch'
}

export enum GridJustifyItems {
  // Aligns items to be flush with the start edge of their cell.
  Start = 'start',
  // Aligns items to be flush with the end edge of their cell.
  End = 'end',
  // Aligns items in the center of their cell.
  Center = 'center',
  // Fills the whole width of the cell.
  Stretch = 'stretch'
}

export function grid (
  templateRows: string = '1fr',
  templateColumns: string = '1fr',
  alignItems: GridAlignItems = GridAlignItems.Stretch,
  justifyItems: GridJustifyItems = GridJustifyItems.Stretch
): CSSProperties {
  return {
    display: 'grid',
    gridTemplateRows: templateRows,
    gridTemplateColumns: templateColumns,
    alignItems: alignItems,
    justifyItems: justifyItems,
    width: '100%',
    height: '100%'
  }
}

export function gridCell (
  rowStart: number = 1,
  rowEnd: number = 1,
  columnStart: number = 1,
  columnEnd: number = 1
): CSSProperties {
  return {
    gridRow: `${rowStart} / ${rowEnd}`,
    gridColumn: `${columnStart} / ${columnEnd}`
  }
}

/* CSS Flex box */
export enum FlexDirection {
  // Left to right in ltr; right to left in rtl.
  Row = 'row',
  // Right to left in ltr; left to right in rtl.
  RowReverse = 'row-reverse',
  // Same as row but top to bottom.
  Column = 'column',
  // Same as row-reverse but bottom to top.
  ColumnReverse = 'column-reverse'
}

export enum FlexWrap {
  // All flex items will be on one line.
  NoWrap = 'nowrap',
  // Flex items will wrap onto multiple lines, from top to bottom.
  Wrap = 'wrap',
  // Flex items will wrap onto multiple lines from bottom to top.
  WrapReverse = 'wrap-reverse'
}

export enum FlexAlignItems {
  // Stretch to fill the container (still respect min-width/max-width).
  Stretch = 'stretch',
  // Cross-start margin edge of the items is placed on the cross-start line.
  Start = 'flex-start',
  // Cross-end margin edge of the items is placed on the cross-end line.
  End = 'flex-end',
  // Items are centered in the cross-axis.
  Center = 'center',
  // Items are aligned such as their baselines align.
  Baseline = 'baseline'
}

export enum FlexAlignContent {
  // Lines packed to the start of the container.
  Start = 'flex-start',
  // Lines packed to the end of the container.
  End = 'flex-end',
  // Lines packed to the center of the container.
  Center = 'center',
  // Lines evenly distributed; the first line is at the start of the container while the last one is at the end.
  SpaceBetween = 'space-between',
  // Lines evenly distributed with equal space around each line.
  SpaceAround = 'space-around',
  // Lines stretch to take up the remaining space.
  Stretch = 'stretch'
}

export enum FlexJustifyContent {
  // Items are packed toward the start line.
  Start = 'flex-start',
  // Items are packed toward to end line.
  End = 'flex-end',
  // Items are centered along the line.
  Center = 'center',
  // Items are evenly distributed in the line; first item is on the start line, last item on the end line.
  SpaceBetween = 'space-between',
  // Items are evenly distributed in the line with equal space around them.
  // Note that visually the spaces aren't equal, since all the items have equal space on both sides.
  // The first item will have one unit of space against the container edge, but two units of space
  // between the next item because that next item has its own spacing that applies.
  SpaceAround = 'space-around',
  // Items are distributed so that the spacing between any two items (and the space to the edges) is equal.
  SpaceEvenly = 'space-evenly'
}

export function flex (
  direction: FlexDirection = FlexDirection.Row,
  wrap: FlexWrap = FlexWrap.NoWrap,
  alignItems: FlexAlignItems = FlexAlignItems.Stretch,
  alignContent: FlexAlignContent = FlexAlignContent.Stretch,
  justifyContent: FlexJustifyContent = FlexJustifyContent.Start
): CSSProperties {
  return {
    display: 'flex',
    flexDirection: direction,
    flexWrap: wrap,
    alignItems: alignItems,
    alignContent: alignContent,
    justifyContent: justifyContent
  }
}
